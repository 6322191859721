<template>
  <div class="p-6 pr-6 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          Programme
        </div>

        <div class="w-1/2 flex justify-end">
<!--          <div class="w-1/2">-->
<!--            <inputo :icon="icons.search" @info="retourSearch" height="50px" placeholder="Rechercher..." />-->
<!--          </div>-->
          <buton
              label="Ajouter un programme"
              class="w-2/5"
              size="18px"
              rounded="6px"
              weight="700"
              background="#312879"
              @info="goTo()"
          />

        </div>
      </div>

      <div class="mt-6">
        <global-view
          :search="recherche"
          :filtrage="valueCategorie"
          @filt="filtrage = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../../assets/icons/search.svg'
import globalView from '../../component/cdevs/partners/globalData'
// import inputo from '../../component/helper/form/input'
import buton from '../../component/helper/add/button'

export default {
  name: "Index",

  components: {
    globalView,
    buton,
    // inputo,
  },

  data () {
    return {
      icons: {
        search
      },
      recherche: null,
    }
  },

  methods: {
    retourSearch (answer) {
      this.recherche = answer
    },

    goTo () {
      this.$router.push('nouveauSfd')
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
